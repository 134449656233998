import React, {useState} from 'react';
import {
  Button,
  ShareButton,
  OpenToggle
} from "..";
import { slugify } from '../../../../../utils/string_util';
import Social from '../../../../components/social/social';

const ListItem = ({
  icon,
  hoverIcon,
  rank = '01',
  rankIcon = "",
  title,
  blurb,
  link,
  altLink = false,
  description,
  active,
  bgColor = "#f7f7f7",
  activeBgColor = "#ffffff",
  canCollapse = true,
  ranked = true,
  withArrow = false,
  category,
  subtitle,
  disableToggle=false,
  showShare=true,
  onClick = () => {},
  socialText = ""
}) => {
  const [iconHover, setIconHover] = useState(false);
  const isTop10 = parseInt(rank) <= 10;

  const handleShareClick = (e) => {
    const shareContainer = e.target.closest('.shareButton__container').nextElementSibling;
    const shareButtons = shareContainer.firstElementChild;
    if(shareButtons.style.left !== '0px') {
      shareContainer.firstElementChild.style.left = '0px';
    }else{
      shareContainer.firstElementChild.style.left = '-300px';
    }
  }

  const handleClick = (event) => {
    if (altLink && event.target.localName !== 'span' && event.target.localName !== 'i' && event.target.localName !== 'ul' && event.target.localName !== 'li') {
      window.open(altLink, '_blank');
    }
  }

  return (
    <div
      className={`listItem__container listItem__container${active ? '--active' : '--default'}`}
      style={{
        backgroundColor: active ? activeBgColor : bgColor
      }}
      onMouseOver={e => setIconHover(true)} onMouseOut={e => setIconHover(false)}
      onTouchStart={e => setIconHover(true)} onTouchEnd={e => setIconHover(false)}
    >
      <div className="listItem__container__inner" onClick={handleClick}>
        <div className="listItem__container__upper">
          {ranked && (
            <div className={`listItem__rank${isTop10 ? '--top10' : ''}`}>{
              isTop10 && rankIcon  ?
              <img
                alt=""
                src={`${rankIcon}${rank}.svg`}/> : rank}
            </div>
          )}
          {icon && (
            <div className={`listItem__icon`}>
              <img src={iconHover ? hoverIcon : icon} alt={`icon for ${title || 'list item'}`} />
            </div>
          )}
          <div className={`listItem__meta listItem__meta${isTop10 ? '--top10' : '--standard'}`}>
            {category && (<div className={`listItem__category`}>{category}</div>)}
            <div className={`listItem__title ${withArrow ? 'withArrow' : ''}`}>{title}</div>
            {subtitle && (<div className={`listItem__subtitle`}>{subtitle}</div>)}
            <div className="listItem__blurb" dangerouslySetInnerHTML={{__html: blurb}} />
            {showShare &&
            <div className="listItem__share">
              <ShareButton label="Share" onClick={handleShareClick} />
              <div className="shareButtons__container">
                <Social
                  empty={false}
                  modifier='mic-hub'
                  enableSticky={false}
                  uri={encodeURIComponent(`https://www.fastcompany.com/most-innovative-companies/list/${parseInt(rank, 10)}`)}
                  text={socialText || ''}
                  horizontal
                />
              </div>
            </div>}
          </div>
          {canCollapse && !disableToggle && (
            <div className="listItem__expand">
              <OpenToggle
                active={active}
                color="#000000"
                activeColor="#000000"
              />
            </div>
          )}
          {!canCollapse && !withArrow && (
            <div className={`listItem__arrow ${isTop10 ? 'top_ten' : ''}`}>
              →
            </div>
          )}
        </div>
        <div className={`listItem__container__lower listItem__container__lower${active ? '--open' : ''}`}>
            {ranked && (
              <div className={`listItem--hidden listItem--mobile-none listItem__rank${isTop10 ? '--top10' : ''}`}>{rank}</div>
            )}
            <div className={`listItem__meta listItem__meta${isTop10 ? '--top10' : ''}`}>
              <div className="listItem__description" dangerouslySetInnerHTML={{__html: description}} />
              {canCollapse &&
                <div className="listItem__actions">
                  {link && (
                    <a className="listItem__more" target="_blank" href={link}>
                      <Button rounded label="More" bgColor="#EF5B24" labelHoverColor="#ffffff" />
                    </a>
                  )}
                  <ShareButton label="Share" onClick={handleShareClick} />
                  <div className="shareButtons__container">
                    <Social
                      empty={false}
                      enableSticky={false}
                      modifier="mic-year-sector"
                      uri={encodeURIComponent(`https://www.fastcompany.com/most-innovative-companies/list#${slugify(title)}`)}
                      text={encodeURIComponent(
                        `@FastCompany's Most Innovative Companies 2022 #FCMostInnovative`
                      )}
                    />

                  </div>
                </div>
              }
            </div>
            {canCollapse && (
              <div className="listItem--hidden listItem--mobile-none listItem__expand">
                <OpenToggle
                  active={active}
                  color="#000000"
                  activeColor="#c7c7c7"
                />
              </div>
            )}
        </div>
      </div>
    </div>
  )
}

ListItem.displayName = 'ListItem';

export default ListItem;
